.container {
  font-family: var(--font-family);
}
.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.totalDuration {
  font-size: 1.2em;
  font-family: var(--font-family);
  font-weight: 500;
  color: var(--primary-text-color);
  padding-left: 15px;
  border-left: 1px solid var(--primary-text-color);
}
.totalDurationWithtopborder {
  padding-top: 10px;
  border-top: 1px solid var(--primary-text-color);
  font-size: 1.4em;
  font-family: var(--font-family);
  font-weight: 500;
  color: var(--primary-text-color);
}
