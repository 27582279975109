.container {
  position: relative;
  margin: 15px;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.addTimeDialogContent {
  width: 504px !important;
  min-height: min(528px, 90vh);
  padding: 0px;
  overflow-x: auto;
  background-color: white;
  z-index: 9999999999;
}

.weekDuration {
  font-weight: 700;
  font-size: 1.2em;
}

.weekDayFormat {
  font-size: 1em;
  color: var(--secondary-text-color);
}

.weekDateFormat {
  font-size: 0.9em;
  color: var(--secondary-text-color);
}

.event {
  color: var(--primary-text-color);
  border-radius: 7px;
}

.timeRangeInfo {
  font-size: 80%;
  color: var(--secondary-text-color);
}

.commentInfo {
  color: var(--secondary-text-color);
  font-style: italic;
  padding-top: "5px";
}
