:root {
  --font-family: Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic,
    Noto Sans JP, sans-serif;
  --title-font-family: Poppins, Roboto, Noto Sans Hebrew, Noto Kufi Arabic,
    Noto Sans JP, sans-serif;
  /* line-height: 1.5;
  font-weight: 400; */
  background-color: var(--primary-background-color);
}

#root {
  background-color: var(--primary-background-color);
}

a {
  font-weight: 500;
  color: var(--primary-color);
  text-decoration: inherit;
}
a:hover {
  color: var(--primary-hover-color);
}

html {
  background-color: var(--primary-background-color);
}

body {
  margin: 0;
  display: flex;
  min-width: 320px;
  min-height: 100vh;
  font-family: var(--font-family);
  z-index: -1;
  background-color: var(--primary-background-color);
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: var(--primary-hover-color);
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

.collapsed {
  display: none;
}

.collapsed-group > div:not(.collapse-group-heading) {
  display: none;
}

.collapse-group-heading::before {
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  content: "";
  border: solid var(--icon-color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
  margin-top: 7px;
  transform: rotate(-135deg);
}

.collapsed-group .collapse-group-heading::before {
  transform: rotate(45deg);
}

.collapse-group-heading {
  display: flex;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  font-weight: 800;
}

/* React Big Calendar CSS - START */
.rbc-month-view {
  height: 100vh;
}
.rbc-off-range-bg {
  background-color: var(--disabled-background-color) !important;
  border-top: 1px solid var(--layout-border-color);
  border-bottom: 1px solid var(--layout-border-color);
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid var(--layout-border-color);
}

.rbc-header {
  font-weight: 400;
  font-size: 100%;
  color: var(--primary-text-color);
  height: 60px;
  border-bottom: 1px solid var(--layout-border-color);
}

.rbc-header + .rbc-header {
  border-left: 1px solid var(--layout-border-color);
}
.rbc-day-bg + .rbc-day-bg,
.rbc-time-header-content {
  border-left: 1px solid var(--layout-border-color);
}

.rbc-calendar {
  min-height: 600px;
  min-width: 1050px;
  color: var(--primary-text-color);
}

.rbc-time-view,
.rbc-month-view {
  border: 1px solid var(--layout-border-color);
}

.rbc-today {
  background-color: transparent;
}

.rbc-timeslot-group {
  min-width: 160px;
  border-left: 1px solid var(--ui-border-color);
  border-bottom: 1px solid var(--ui-border-color);
}

.rbc-month-row {
  display: inline-table !important;
  flex: 0 0 0 !important;
  min-height: 85px !important;
  width: 1200px;
}

.rbc-event {
  background-color: var(--primary-highlighted-color);
  color: inherit;
  margin: 2px 0px 2px 0px;
}

.rbc-event .rbc-event-content {
  padding-top: 3px;
}

.rbc-day-slot .rbc-event {
  border: 1px solid var(--layout-border-color);
  background-color: var(--primary-highlighted-color);
  box-shadow: var(--box-shadow-small);
  margin-left: 5px;
}

.rbc-event {
  border-radius: 2px;
  padding-left: 2px;

  box-shadow: var(--box-shadow-xs);
}

.rbc-day-slot .rbc-time-slot {
  border-top: none;
}

/* .rbc-day-slot .rbc-event:hover {
  border-left: 5px solid var(--primary-hover-color);
} */

.rbc-toolbar button {
  background-color: var(--primary-color);
  color: var(--text-color-on-primary);
}

.rbc-toolbar button:hover {
  background-color: var(--primary-hover-color);
  color: var(--text-color-on-primary);
}
.rbc-toolbar button.rbc-active {
  background-color: var(--primary-selected-hover-color);
  color: var(--primary-text-color);
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  background-color: var(--primary-selected-hover-color);
  color: var(--primary-text-color);
  /* border-color: #8c8c8c; */
}
/* .rbc-toolbar button .rbc-active:hover {
  background-color: var(--primary-selected-hover-color);
  color: var(--primary-text-color);
} */

/* 
.ant-picker-dropdown {
  z-index: 1056;
} */

.rbc-day-slot .rbc-event-label {
  color: var(--secondary-text-color);
}

.rbc-toolbar-label {
  color: var(--primary-text-color);
  font: var(--font-h2-normal);
}

.rbc-day-bg:hover::after,
.rbc-timeslot-group.rbc-time-slot:hover::after {
  content: "+ Add";
  margin: auto;
  width: 96%;
  height: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--primary-background-hover-color);
}

.rbc-day-bg:hover,
.rbc-day-slot .rbc-time-slot:hover {
  cursor: pointer;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
}

.rbc-event-label {
  display: none;
}

/* React Big Calendar CSS - END */

/* Antd START */
.ant-picker,
.ant-picker:focus,
.ant-picker-panel-layout,
.ant-picker-cell,
.ant-picker-content,
.ant-picker-dropdown .ant-picker-content th,
.ant-picker-dropdown .ant-picker-header,
.ant-picker-dropdown .ant-picker-header > button,
.ant-picker-outlined:focus-within,
.ant-picker-dropdown .ant-picker-cell-in-view,
.ant-picker-time-panel-cell-inner,
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner,
input.ant-input-outlined,
input.ant-input-outlined:hover,
input.ant-input-outlined:focus,
input.ant-input-outlined:focus-within,
.ant-select-selection-placeholder,
.ant-input-outlined,
.anticon,
.ant-tabs-tab-btn {
  background-color: var(--primary-background-color);
  color: var(--primary-text-color);
  border-color: var(--layout-border-color);
  border-radius: 4px;
}

.ant-tabs-tab {
  margin-left: 10px;
}

.ant-picker:hover,
.ant-picker-outlined:hover,
.ant-input-outlined:hover {
  background-color: var(--primary-background-color);
  color: var(--secondary-text-color);
}
/* 
td.ant-picker-cell {
  color: var(--disabled-text-color);
  background-color: var(--disabled-background-color);
} */
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end {
  color: var(--primary-text-color);
  background-color: var(--primary-background-hover-color);
}

div.ant-popover-content .ant-popover-inner,
div.ant-popover-content .ant-popover-inner .ant-popover-title,
div.ant-popover-content .ant-popover-inner .ant-popover-inner-content {
  color: var(--text-color-on-inverted);
  background-color: var(--inverted-color-background);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )::before {
  color: var(--primary-text-color);
  background-color: var(--primary-background-hover-color);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: var(--primary-color);
}

/* Antd END */

/* MUI Start */
.MuiBox-root,
.MuiTableContainer-root .MuiTable-root,
th.MuiTableCell-root,
td.MuiTableCell-root,
div.MuiAlert-message,
.MuiChip-label.MuiChip-sizeMedium,
.MuiChip-deletable,
.MuiCheckbox-colorPrimary > input > svg,
.MuiSvgIcon-root .MuiSvgIcon-fontSizeSmall,
.MuiList-root.MuiMenu-list,
.MuiListItemIcon-root svg,
.MuiButtonBase-root svg,
.MuiButtonBase-root svg.MuiSvgIcon-root,
.MuiBadge-root .MuiTableSortLabel-root svg,
.MuiChip-label.MuiChip-labelMedium,
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiSvgIcon-root
  svg,
button .MuiButtonBase-root .MuiButton-root .MuiButton-text,
div.MuiPaper-root .MuiBox-root,
div.MuiPaper-root,
span.MuiTypography-root.MuiFormControlLabel-label.Mui-disabled,
button.MuiButtonBase-root,
.ant-picker .ant-picker-input > input::placeholder,
.MuiInputBase-root
  .MuiInput-root
  .MuiInput-underline
  .MuiInputBase-colorPrimary
  .MuiInputBase-fullWidth
  .Mui-focused
  .MuiInputBase-formControl
  .MuiInputBase-adornedStart
  .MuiInputBase-adornedEnd,
div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd
  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd,
input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd,
.ant-picker-input.ant-picker-input-active.ant-picker-input-placeholder > input,
.ant-picker-input.ant-picker-input-placeholder > input,
p.MuiTypography-root.MuiTypography-body1,
div
  > div
  > div
  > div.MuiCollapse-root.MuiCollapse-horizontal.MuiCollapse-entered
  > div
  > div
  > div
  > div {
  background-color: var(--primary-background-color);
  color: var(--primary-text-color);
}
.ant-picker-dropdown
  .ant-picker-cell:not(.ant-picker-cell-in-view)
  .ant-picker-cell-inner {
  color: var(--disabled-text-color);
}

div.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd::before,
div
  > div
  > div
  > div.MuiCollapse-root.MuiCollapse-horizontal.MuiCollapse-entered
  > div
  > div
  > div
  > div {
  border-bottom-color: var(--ui-border-color);
}

div.MuiCollapse-root.MuiCollapse-horizontal.MuiCollapse-entered
  div.MuiCollapse-wrapper.MuiCollapse-horizontal
  div.MuiCollapse-wrapperInner.MuiCollapse-horizontal
  div.MuiFormControl-root.MuiTextField-root
  div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
  border-color: var(--ui-border-color);
}

tr.MuiTableRow-root.Mui-selected
  > td.MuiTableCell-root.MuiTableCell-body::after {
  background-color: var(--primary-highlighted-color);
  color: var(--primary-text-color);
}

.MuiSvgIcon-root .MuiSvgIcon-colorPrimary,
.MuiSvgIcon-root .MuiSvgIcon-colorSecondary {
  color: var(--primary-text-color);
}

td.MuiTableCell-root
  .MuiTableCell-body
  .MuiTableCell-alignLeft
  .MuiTableCell-sizeMedium::after {
  background-color: var(--primary-background-color);
  color: var(--primary-text-color);
}

.MuiButtonBase-root.MuiTableSortLabel-root
  > svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon {
  color: var(--primary-text-color) !important;
}

/* MUI End */

div.monday-storybook-tooltip_title > div {
  height: 100%;
}
