.tabHeading {
  font-size: 16px;
  padding: 10px;
}

.footer {
  padding-top: "20px";
  font: var(--font-h4);
  color: var(--primary-text-color);
  text-align: center;
}
