.topContainer {
  display: grid;
  grid-template-rows: 1fr;
  transition: "all 200ms ease 0s";
}

.gridContainer {
  border-top: 1px solid #a1a4ae;
  position: relative;
  height: 100%;
  width: 450px;
  display: grid;
  padding: 10px;
  margin-left: 10px;
  grid-template-rows: repeat(7, auto) 1fr;
  grid-template-columns: repeat(5, minmax(0px, 1fr));
  grid-template-areas:
    "title            title        title        title        title       "
    "workItem     workItem     workItem     workItem     workItem    "
    "date         date         .            fromToToggle    fromToToggle"
    "fromTo       fromTo       fromTo       .            .           "
    "duration     duration     quickTime    quickTime    quickTime   "
    "comment      comment      comment      comment      comment     "
    "customFields customFields customFields customFields customFields"
    ".            .            .            cancel       save        ";
}

.title {
  grid-area: title;
  font-weight: 600;
  font-size: 1.4em;
  padding: 5px;
  margin: 5px 5px 15px 0px;
  align-self: center;
  justify-self: center;
}

.workItem {
  grid-area: workItem;
  margin: 5px 5px 15px 0px;
}

.workItemDropDown {
  margin-bottom: 15px;
}

.date {
  grid-area: date;
  margin-bottom: 15px;
}

.fromToToggle {
  grid-area: fromToToggle;
  justify-self: self-end;
}

.fromTo {
  grid-area: fromTo;
}

.duration {
  grid-area: duration;
  margin-bottom: 15px;
}

.quickTime {
  grid-area: quickTime;
  justify-self: end;
  margin-top: 40px;
}

.comment {
  grid-area: comment;
  margin-bottom: 15px;
}

.customFields {
  grid-area: customFields;
  margin-top: 4px;
}

.cancel {
  grid-area: cancel;
  justify-self: end;
  align-self: center;
}

.save {
  grid-area: save;
  justify-self: end;
  align-self: center;
}

.dayPicker {
  transition: "height .2s ease-in-out";
  position: relative;
  overflow: hidden;
}
